import { Stack } from '@mui/material';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import useFeedbackUnified from '@arcanna/pages/FeedbackUnified/hooks/useFeedbackUnified';
import useFeedbackUnifiedBuckets from '@arcanna/pages/FeedbackUnified/hooks/useFeedbackUnifiedBuckets';
import useFeedbackUnifiedFilters from '@arcanna/pages/FeedbackUnified/hooks/useFeedbackUnifiedFilters';
import { useCallback, useEffect, useMemo } from 'react';
import { Pagination, Table } from '@arcanna/generic';
import { TFeedbackTableRowData } from '@arcanna/pages/Feedback/FeedbackTable/FeedbackTable.types';
import { Row, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { getColumns, getTableRowData } from '@arcanna/pages/Feedback/FeedbackTable/FeedbackTable.utils';
import { TABLE_SIZES } from 'src/_srcMUI/shared/generic/Table/components/TableSizeSelector/TableSizeSelector.constants';
import FeedbackUnifiedTableFooter from './components/FeedbackUnifiedTableFooter';

const FEEDBACK_UNIFIED_TABLE_KEY = 'feedbackUnified';

function FeedbackUnifiedTable() {
  const { t } = useTranslation(['feedback', 'job']);
  const { getFilterSource, allLabelsOnChangeCancel, allLabelsOnChangeConfirm } = useFeedbackUnified();
  useFeedbackUnifiedBuckets();
  useFeedbackUnifiedFilters();

  const state = useFeedbackUnifiedContext((context) => context.state);
  const tableSlice = useFeedbackUnifiedContext((context) => context.tableSlice);
  const advancedFilters = useFeedbackUnifiedContext((context) => context.advancedFilters);
  const setExpandedBucketByIndex = useFeedbackUnifiedContext((context) => context.setExpandedBucketByIndex);

  const data: TFeedbackTableRowData[] = useMemo(() => getTableRowData(state.tableData), [state.tableData]);

  const {
    tableState,
    setTableState,
    tableSize: activeTableSize,
    setTableSize: setActiveTableSize,
    customRowSizeValue: activeCustomRowSizeValue,
    setCustomRowSizeValue: setActiveCustomRowSizeValue
  } = Table.useSaveTablePreferences({
    tableKey: FEEDBACK_UNIFIED_TABLE_KEY
  });

  const columns = useMemo(() => {
    return getColumns({
      t,
      extraBaseColumnNames: ['job_title'],
      batchBucketDynamicColumns: tableSlice.batchBucketDynamicColumns,
      jobInfoCustomLabels: state.jobCustomLabels,
      activeFilters: advancedFilters.activeFilters,
      addAdvancedFilter: advancedFilters.addAdvancedFilter,
      getFilterSourceByName: (name: string) => getFilterSource(name, state.filtersFields),
      activeTableSize
    });
    // eslint-disable-next-line
  }, [
    tableSlice.batchBucketDynamicColumns,
    t,
    state.jobCustomLabels,
    advancedFilters.activeFilters,
    advancedFilters.addAdvancedFilter,
    getFilterSource,
    state.filtersFields,
    activeTableSize
  ]);

  const tableInstance = useReactTable<TFeedbackTableRowData>({
    data: data,
    columns: columns,
    // TODO: enable sorting when fixed
    enableSorting: false,
    enableHiding: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    columnResizeMode: 'onChange',
    defaultColumn: {
      minSize: 60,
      maxSize: 800
    },
    state: {
      ...tableState,
      sorting: tableSlice.sortingState
    },
    onSortingChange: tableSlice.setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id + '_' + row.job_id,
    manualSorting: true,
    onStateChange: setTableState
  });

  useEffect(() => {
    // reset the columnOrder if the number of columns has changed
    if (!tableSlice.isBucketsTableLoaded) {
      return;
    }

    const columnOrder = tableInstance.getState().columnOrder;

    if (!columnOrder.length || columnOrder.length !== columns.length) {
      tableInstance.setColumnOrder(columns.map((column) => column.id ?? ''));
    }
    // eslint-disable-next-line
  }, [columns, tableSlice.isBucketsTableLoaded]);

  const onRowClick = useCallback(
    (_: Row<TFeedbackTableRowData>, rowIndex: number) => {
      setExpandedBucketByIndex(rowIndex);
    },
    [setExpandedBucketByIndex]
  );

  const emphasizedRows = useMemo(
    () => (state.expandedBucketIndex !== undefined ? [state.expandedBucketIndex] : []),
    [state.expandedBucketIndex]
  );

  const selectedBuckets = useMemo(
    () => Object.keys(tableInstance.getState().rowSelection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableInstance, tableInstance.getState().rowSelection]
  );

  return (
    <Stack direction="column" gap={2}>
      <Table.component<TFeedbackTableRowData>
        onRowClick={onRowClick}
        tableInstance={tableInstance}
        isLoading={!tableSlice.isBucketsTableLoaded}
        emphasizedRows={emphasizedRows}
        noResultsMessageTitle={t('common:noResults')}
        noResultsMessageSubtitle={t('common:adjustSearchCriteria')}
        hasMinimizedView
        onTableSizeChange={setActiveTableSize}
        customRowSizeValue={activeCustomRowSizeValue}
        onCustomRowSizeChange={setActiveCustomRowSizeValue}
        defaultTableSize={activeTableSize as TABLE_SIZES}
        hasColumnReordering
      />
      <Pagination
        page={tableSlice.paginationState.pageIndex}
        total={tableSlice.totalRecordsCount}
        pageSize={tableSlice.paginationState.pageSize}
        onPageChange={(pageIndex) => {
          tableInstance.resetRowSelection();
          tableSlice.setPageIndex(pageIndex);
        }}
        onPageSizeChange={(pageSize) => {
          tableInstance.resetRowSelection();
          tableSlice.setPageSize(pageSize);
        }}
      />
      <FeedbackUnifiedTableFooter
        selectedBuckets={selectedBuckets}
        allLabelsOnChangeCancel={() => allLabelsOnChangeCancel(tableInstance)}
        allLabelsOnChangeConfirm={() => allLabelsOnChangeConfirm(tableInstance)}
      />
    </Stack>
  );
}

export default FeedbackUnifiedTable;

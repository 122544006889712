import { Pagination, Table } from '@arcanna/generic';
import { Row, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { TFeedbackTableRowData } from './FeedbackTable.types';
import { getColumns, getTableRowData } from './FeedbackTable.utils';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { TABLE_SIZES } from 'src/_srcMUI/shared/generic/Table/components/TableSizeSelector/TableSizeSelector.constants';
import { useJobIdFromUrl } from '@arcanna/hooks';
import LabelContextProvider from '../FeedbackContext/LabelContextProvider';
import FeedbackTableFooter from './components/FeedbackTableFooter';

function FeedbackTable({ jobInfos }: { jobInfos: JobInfoResponse[] }) {
  const { t } = useTranslation(['feedback', 'job']);
  const filterFields = useFeedbackContext((context) => context.filtersSlice.filterFields);
  const jobInfoCustomLabels = useFeedbackContext((context) => context.jobInfoSlice.jobInfoCustomLabels);
  const advancedFilters = useFeedbackContext((context) => context.filtersSlice.advancedFilters);
  const tableSlice = useFeedbackContext((context) => context.tableSlice);
  const setExpandedBucketByIndex = useFeedbackContext((context) => context.expandedBucketSlice.setExpandedBucketByIndex);
  const expandedBucketByIndex = useFeedbackContext((context) => context.expandedBucketSlice.expandedBucket.index);

  const { jobId } = useJobIdFromUrl();

  const feedbackTableKey = useMemo(() => 'feedback_' + jobId, [jobId]);

  const getFilterSourceByName = useCallback(
    (name: string) => {
      if (filterFields == null || filterFields.length === 0) {
        return '';
      }

      const filterByName = filterFields.find((filter) => filter.name === name || filter.source === name);

      return filterByName ? filterByName.source : '';
    },
    [filterFields]
  );

  const {
    tableState,
    setTableState,
    tableSize: activeTableSize,
    setTableSize: setActiveTableSize,
    customRowSizeValue: activeCustomRowSizeValue,
    setCustomRowSizeValue: setActiveCustomRowSizeValue
  } = Table.useSaveTablePreferences({
    tableKey: feedbackTableKey
  });

  const columns = useMemo(
    () =>
      getColumns({
        t,
        extraBaseColumnNames: [],
        batchBucketDynamicColumns: tableSlice.batchBucketDynamicColumns,
        jobInfoCustomLabels: jobInfoCustomLabels,
        activeFilters: advancedFilters.activeFilters,
        addAdvancedFilter: advancedFilters.addAdvancedFilter,
        getFilterSourceByName,
        activeTableSize
      }),
    [
      t,
      tableSlice.batchBucketDynamicColumns,
      jobInfoCustomLabels,
      advancedFilters.activeFilters,
      advancedFilters.addAdvancedFilter,
      getFilterSourceByName,
      activeTableSize
    ]
  );

  const data: TFeedbackTableRowData[] = useMemo(() => getTableRowData(tableSlice.batchBucketRows), [tableSlice.batchBucketRows]);

  const getRowId = useCallback((row: TFeedbackTableRowData) => row.id, []);

  const tableInstance = useReactTable<TFeedbackTableRowData>({
    data: data,
    columns: columns,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    columnResizeMode: 'onChange',
    defaultColumn: {
      minSize: 60,
      size: 240,
      maxSize: 800
    },
    state: {
      ...tableState,
      sorting: tableSlice.sortingState
    },
    onSortingChange: tableSlice.setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
    manualSorting: true,
    onStateChange: setTableState
  });

  useEffect(() => {
    // reset the columnOrder if the number of columns has changed
    const columnOrder = tableInstance.getState().columnOrder;

    if (!columnOrder.length || columnOrder.length !== columns.length) {
      tableInstance.setColumnOrder(columns.map((column) => column.id ?? ''));
    }
    // eslint-disable-next-line
  }, [columns]);

  const onRowClick = useCallback(
    (_: Row<TFeedbackTableRowData>, rowIndex: number) => {
      setExpandedBucketByIndex(rowIndex);
    },
    [setExpandedBucketByIndex]
  );

  const emphasizedRows = useMemo(
    () => (expandedBucketByIndex !== undefined ? [expandedBucketByIndex] : []),
    [expandedBucketByIndex]
  );

  const selectedBuckets = useMemo(
    () => Object.keys(tableInstance.getState().rowSelection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableInstance, tableInstance.getState().rowSelection]
  );

  return (
    <LabelContextProvider>
      <Table.component<TFeedbackTableRowData>
        onRowClick={onRowClick}
        tableInstance={tableInstance}
        isLoading={tableSlice.isBucketsLoading}
        emphasizedRows={emphasizedRows}
        noResultsMessageTitle={t('common:noResults')}
        noResultsMessageSubtitle={t('common:adjustSearchCriteria')}
        hasMinimizedView
        onTableSizeChange={setActiveTableSize}
        customRowSizeValue={activeCustomRowSizeValue}
        onCustomRowSizeChange={setActiveCustomRowSizeValue}
        defaultTableSize={activeTableSize as TABLE_SIZES}
        hasColumnReordering
      />
      <Pagination
        page={tableSlice.paginationState.pageIndex}
        total={tableSlice.totalRecordsCount}
        pageSize={tableSlice.paginationState.pageSize}
        onPageChange={(pageIndex) => {
          tableInstance.resetRowSelection();
          tableSlice.setPageIndex(pageIndex);
        }}
        onPageSizeChange={(pageSize) => {
          tableInstance.resetRowSelection();
          tableSlice.setPageSize(pageSize);
        }}
      />
      <FeedbackTableFooter onResetRow={tableInstance.resetRowSelection} selectedBuckets={selectedBuckets} jobInfos={jobInfos} />
    </LabelContextProvider>
  );
}

export default FeedbackTable;

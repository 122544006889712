import { MinusOutlined } from '@ant-design/icons';
import { StateChip } from '@arcanna/generic';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';
import { Tooltip, useTheme } from '@mui/material';

type BucketBucketTrainingProps = {
  trainingStatus: BucketTrainingStatus | null | undefined;
  trainedWith: string;
};

export function BucketTrainingStatusTag({ trainingStatus: trainingStatus, trainedWith }: BucketBucketTrainingProps) {
  const { t } = useTranslation('feedback');
  const { palette } = useTheme();

  const StateTagLabelMap: Record<BucketTrainingStatus, string> = {
    [BucketTrainingStatus.NEW]: t('stateTagLabelNew'),
    [BucketTrainingStatus.IN_MODEL]: t('trainingStatusTagInModel')
  };

  const StateTagTooltipMap: Record<BucketTrainingStatus, string> = {
    [BucketTrainingStatus.NEW]: t('trainingStatusTagTooltipNew'),
    [BucketTrainingStatus.IN_MODEL]: t('trainingStatusTagTooltipInModel', { trained_with: trainedWith })
  };

  const StateTagColorMap: Record<BucketTrainingStatus, string> = useMemo(() => {
    return {
      [BucketTrainingStatus.NEW]: palette.secondary[300],
      [BucketTrainingStatus.IN_MODEL]: palette.success[400]
    };
  }, [palette]);

  return (
    <Tooltip arrow placement="left" title={trainingStatus ? StateTagTooltipMap[trainingStatus] : t('stateTagTooltipMissing')}>
      {trainingStatus ? (
        <StateChip color={StateTagColorMap[trainingStatus]} text={StateTagLabelMap[trainingStatus]} size="small" />
      ) : (
        <MinusOutlined />
      )}
    </Tooltip>
  );
}

export default BucketTrainingStatusTag;

import { AdvancedFilters, RefreshButton, TimeSelector } from '@arcanna/components';
import { useQueryClient } from 'react-query';
import { config } from 'src/config';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { Stack } from '@mui/material';
import FeedbackFlowQuickFilters from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlowQuickFilters';
import FeedbackFlowConsensusDecisionFilters from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlowConsensusDecisionFilters';
import DecisionPointsButton from 'src/components/pages/Main/Jobs/Feedback/Flow/DecisionPointsButton';
import { useJobContext } from 'src/components/pages/Main/Jobs/Job.context';
import { useTranslation } from 'react-i18next';
import { memo, useCallback, useMemo, useState } from 'react';
import { getTimeOptions } from './FeedbackHeader.utils';
import { FeedbackTimeSelectorOption } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.hook';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';

function FeedbackHeader({ jobInfos }: { jobInfos: JobInfoResponse[] }) {
  const queryClient = useQueryClient();
  const { state: jobState } = useJobContext();
  const { t } = useTranslation();
  const jobInfoSlice = useFeedbackContext((context) => context.jobInfoSlice);
  const triggerBucketsReload = useFeedbackContext((context) => context.tableSlice.triggerBucketsReload);
  const {
    advancedFilters,
    clearAdditionalFilters,
    additionalFilters,
    quickFilters,
    setQuickFilters,
    decisionFilters,
    consensusFilters,
    setDecisionFilters,
    setConsensusFilters,
    handleFeedbackTimeSelection
  } = useFeedbackContext((context) => context.filtersSlice);

  const [feedbackTimeSelectorOption, setFeedbackTimeSelectorOption] = useState<FeedbackTimeSelectorOption>(
    FeedbackTimeSelectorOption.ALL_TIME
  );
  const timeSelectorOptions = useMemo(() => getTimeOptions(t), [t]);

  const handleRefresh = useCallback(async () => {
    triggerBucketsReload();
    await queryClient.invalidateQueries([config.api.jobFeedbackFilterFields + `${jobInfoSlice.jobId}`]);
  }, [jobInfoSlice.jobId, queryClient, triggerBucketsReload]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <TimeSelector
            options={timeSelectorOptions}
            currentValue={feedbackTimeSelectorOption}
            handleChange={(event) => {
              const newValue = event.target.value as FeedbackTimeSelectorOption;

              setFeedbackTimeSelectorOption(newValue);
              handleFeedbackTimeSelection(newValue);
            }}
            width={190}
          />
          <FeedbackFlowQuickFilters quickFiltersState={quickFilters} setQuickFilters={setQuickFilters} />
          <FeedbackFlowConsensusDecisionFilters
            jobInfos={jobInfos}
            decisionFilters={decisionFilters}
            consensusFilters={consensusFilters}
            setDecisionFilters={setDecisionFilters}
            setConsensusFilters={setConsensusFilters}
          />
          <AdvancedFilters.Button
            fieldOptions={advancedFilters.fieldOptions}
            operatorOptionsMap={advancedFilters.operatorOptionsMap}
            addAdvancedFilter={advancedFilters.addAdvancedFilter}
            getValueOptions={advancedFilters.getValueOptions}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {jobState.selectedJob && <DecisionPointsButton jobId={jobInfoSlice.jobId} fromPage={t('job:navigation.feedback')} />}
          <RefreshButton onRefresh={handleRefresh} />
        </Stack>
      </Stack>
      <AdvancedFilters.List
        filters={advancedFilters.activeFilters}
        fieldOptions={advancedFilters.fieldOptions}
        operatorOptionsMap={advancedFilters.operatorOptionsMap}
        onDelete={advancedFilters.removeAdvancedFilter}
        onUpdate={advancedFilters.addAdvancedFilter}
        onClearAll={() => {
          advancedFilters.clearAllAdvancedFilters();
          clearAdditionalFilters();
        }}
        getValueOptions={advancedFilters.getValueOptions}
        additionalFilters={additionalFilters}
      />
    </>
  );
}

export default memo(FeedbackHeader);

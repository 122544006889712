import { BoxProps, Stack, Typography } from '@mui/material';
import StyledStateChip from './StyledStateChip.styles';
import React from 'react';

type TStateChipProps = {
  color: string;
  text: string;
  size?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
} & BoxProps;

function StateChip({ color, text, size = 'small', icon, ...otherProps }: TStateChipProps) {
  return (
    <StyledStateChip color={color} size={size} {...otherProps}>
      <Typography
        component="span"
        variant="inherit"
        color="inherit"
        textOverflow="ellipsis"
        overflow="hidden"
        sx={{
          direction: 'rtl'
        }}
      >
        {text}&lrm;
      </Typography>
      {icon && (
        <Stack justifyContent="center" sx={{ marginLeft: '5px' }}>
          {icon}
        </Stack>
      )}
    </StyledStateChip>
  );
}

export default StateChip;

import { TruncatedText } from '@arcanna/components';
import { createColumnHelper } from '@tanstack/react-table';
import { TDPUnifiedTableRowData } from './DPUnifiedTable.types';
import { EIcon, Icon, TCheckboxState } from '@arcanna/generic';
import { TUnifiedDecisionPointRecord } from '@arcanna/models/Jobs';
import _ from 'lodash';
import { FilterFn } from '@tanstack/react-table';
import { Stack, Tooltip } from '@mui/material';
import { TFunction } from 'react-i18next';
import DPCell from './components/DPCell';
import DPFieldNameCell from './components/DPFieldNameCell';
import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { JobStatusRecord } from 'src/components/shared/models/job/JobStatusRecord';

export const getIsBucketModelUpdateInProgress = (jobStatus: JobStatusRecord | undefined) => {
  if (!jobStatus) {
    return true;
  }

  return Boolean(jobStatus?.buckets_model_update_in_progress) || Boolean(jobStatus?.buckets_update_in_progress);
};

export const getIsDPDisabled = (
  dPRecord: TUnifiedDecisionPointRecord,
  jobSummaryRecord: JobSummaryRecord | undefined,
  t?: TFunction
) => {
  const isFieldNotSupported = _.isEmpty(dPRecord);
  const hasDPSupportedType = ['text', 'boolean', 'keyword'].includes(String(dPRecord.fieldType));
  const isBucketModelUpdateInProgress = getIsBucketModelUpdateInProgress(jobSummaryRecord?.status);

  if (isBucketModelUpdateInProgress) {
    // the job has the bucket model update in progress
    return {
      isDisabled: true,
      reason: !t ? undefined : t('decisionPointsUnified:dPDisabledReasons:bucketsModelUpdateInProgress')
    };
  }
  if (isFieldNotSupported) {
    // the field is not supported by the job
    return {
      isDisabled: true,
      reason: !t ? undefined : t('decisionPointsUnified:dPDisabledReasons:fieldNotSupported'),
      checkboxLabel: !t ? undefined : t('decisionPointsUnified:fieldNotExists')
    };
  }
  if (!hasDPSupportedType) {
    // the fieldType is not supported
    return {
      isDisabled: true,
      reason: !t ? undefined : t('decisionPointsUnified:dPDisabledReasons:fieldTypeNotSupported')
    };
  }

  return {
    isDisabled: false,
    reason: !t ? undefined : t('')
  };
};

export const getIsDPDisabledExcludingJobStatus = (dPRecord: TUnifiedDecisionPointRecord) => {
  const isFieldNotSupported = _.isEmpty(dPRecord);
  const hasDPSupportedType = ['text', 'boolean', 'keyword'].includes(String(dPRecord.fieldType));

  return isFieldNotSupported || !hasDPSupportedType;
};

export const getColumns = (
  jobInfos: JobSummaryRecord[],
  handleCheckboxChange: (state: TCheckboxState, fieldName: string, jobId: number | undefined) => void,
  t: TFunction
) => {
  const columnHelper = createColumnHelper<TDPUnifiedTableRowData>();

  return [
    columnHelper.accessor('fieldName', {
      id: 'fieldName',
      header: () => (
        <TruncatedText
          sx={{ paddingLeft: '54px' }}
          variant="subtitle2"
          text={t('decisionPointsUnified:tableColumns.fieldName')}
          ellipsisPosition="start"
          tooltipProps={{
            title: undefined
          }}
        />
      ),
      enableHiding: false,
      enableSorting: true,
      enablePinning: true,
      size: 240,
      sortingFn: 'alphanumeric',
      cell: ({ cell, row }) => (
        <DPFieldNameCell row={row} cell={cell} handleCheckboxChange={handleCheckboxChange} jobSummaryRecords={jobInfos} />
      ),
      meta: {
        isTooltipDisabled: true
      }
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columnHelper.accessor('fieldType' as any, {
      id: 'fieldType',
      header: () => (
        <TruncatedText
          variant="subtitle2"
          ellipsisPosition="start"
          text={t('decisionPointsUnified:tableColumns.fieldType')}
          tooltipProps={{
            title: undefined
          }}
        />
      ),
      enableHiding: false,
      enableSorting: false,
      enablePinning: true,
      size: 10,
      sortingFn: 'alphanumeric',
      cell: ({ row }) => {
        return _.uniq(Object.keys(_.omit(row.original, 'fieldName')).map((jobId) => row.original[Number(jobId)].fieldType))
          .filter(Boolean)
          .join(',');
      },
      meta: {
        isTooltipDisabled: true
      }
    }),
    ...jobInfos
      .map((jobInfoResponse) =>
        jobInfoResponse?.id
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columnHelper.accessor(String(jobInfoResponse.id) as any, {
              id: String(jobInfoResponse.id),
              header: () => (
                <Stack direction={'row'} alignItems={'center'} gap={1} width="100%">
                  <TruncatedText
                    variant="subtitle2"
                    text={jobInfoResponse.title}
                    ellipsisPosition="start"
                    tooltipProps={{
                      title: jobInfoResponse.title,
                      placement: 'top',
                      arrow: false,
                      componentsProps: {
                        tooltip: {
                          sx: {
                            width: 'auto'
                          }
                        }
                      }
                    }}
                  />
                  {getIsBucketModelUpdateInProgress(jobInfoResponse.status) && (
                    <Tooltip
                      title={t('decisionPointsUnified:dPDisabledReasons:bucketsModelUpdateInProgress')}
                      placement="top"
                      arrow
                    >
                      <Stack>
                        <Icon name={EIcon.AlertTriangle} fontSize="small" color="warning" />
                      </Stack>
                    </Tooltip>
                  )}
                </Stack>
              ),
              cell: ({ row }) => {
                if (!jobInfoResponse?.id) {
                  return <></>;
                }

                const decisionPointRecord = row.original[jobInfoResponse.id];

                return (
                  <DPCell
                    decisionPointRecord={decisionPointRecord}
                    fieldName={row.original.fieldName}
                    jobSummaryRecord={jobInfoResponse}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              },
              enableSorting: false,
              enablePinning: false,
              enableGlobalFilter: false,
              enableHiding: false,
              meta: {
                isTooltipDisabled: true,
                isColumnDisabled: getIsBucketModelUpdateInProgress(jobInfoResponse?.status)
              }
            })
          : undefined
      )
      .filter(Boolean)
  ];
};

export const getGlobalFilterFn: FilterFn<TDPUnifiedTableRowData> = (row, columnId, value) => {
  if (!['fieldName', 'fieldType'].includes(columnId)) {
    const sampleValue = String((row.getValue(columnId) as TUnifiedDecisionPointRecord).sampleValue ?? '');

    return sampleValue.includes(value?.toLowerCase());
  }
  return row.original.fieldName.toLowerCase().includes(value?.toLowerCase());
};

import { Box, BoxProps, alpha, styled } from '@mui/material';

const sizeStylesMap = {
  small: {
    fontSize: '12px',
    lineHeight: '1.2',
    padding: '2px 6px 2px',
    borderRadius: '12px'
  },
  medium: {
    fontSize: '12px',
    lineHeight: '1.2',
    padding: '4px 8px',
    borderRadius: '12px'
  },
  large: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '2px 8px',
    borderRadius: '18px'
  }
};

const StyledStateChip = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'color' && propName !== 'size';
  }
})<
  BoxProps & {
    color: string;
    size: 'small' | 'medium' | 'large';
  }
>(({ size, color, onClick }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    padding: sizeStylesMap[size].padding,
    fontSize: sizeStylesMap[size].fontSize,
    lineHeight: sizeStylesMap[size].lineHeight,
    color: color,
    backgroundColor: alpha(color, 0.1),
    borderRadius: sizeStylesMap[size].borderRadius,
    border: '1px solid',
    borderColor: alpha(color, 0.3),

    ...(typeof onClick === 'function'
      ? {
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            textUnderlineOffset: '2px'
          }
        }
      : {})
  };
});

export default StyledStateChip;

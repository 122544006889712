import { TFilterItem } from '@arcanna/components';
import { Icon, EIcon, StateChip, Table } from '@arcanna/generic';
import { Typography } from '@mui/material';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EFilterOperatorWithValue,
  EFilterOperatorWithoutValue
} from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';
import { copyToClipboard } from '@arcanna/utils';
import { useQuickFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/hooks';

type TDecisionPointsColumnItemProps = {
  value: string | undefined;
  columnName: string;
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
  filterSource: string | undefined;
};

function DecisionPointsColumnItem({
  value,
  columnName,
  activeFilters,
  addAdvancedFilter,
  filterSource
}: TDecisionPointsColumnItemProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { getIsFilterPresent, getFilterToApply } = useQuickFilters({ value, filterSource, columnName });

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayedValue = Table.getDisplayedValue(value);
  const hasTooltip = typeof displayedValue === 'string' && displayedValue?.length > 20;

  const renderFilterButton = (
    operatorWithValue: EFilterOperatorWithValue,
    operatorWithoutValue: EFilterOperatorWithoutValue,
    iconName: EIcon,
    label: string
  ) => {
    const { filterToApply } = getFilterToApply(operatorWithValue, operatorWithoutValue);

    return filterToApply && !getIsFilterPresent(filterToApply, activeFilters) ? (
      <MenuItem onClick={() => addAdvancedFilter(filterToApply)} className="MuiMenuItem-root--smaller">
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ padding: 0 }}>
          <Typography variant="subtitle2">{label}</Typography>
          <Icon name={iconName} fontSize="small" />
        </Stack>
      </MenuItem>
    ) : (
      <></>
    );
  };

  return (
    <Stack key={columnName} width={'calc(50% - 8px)'} direction="row" alignItems="flex-start" gap={1}>
      <StateChip
        color={theme.palette.info[500]}
        text={columnName}
        onClick={handleClick}
        sx={{
          minWidth: '33%',
          width: '100px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          '&:hover': { cursor: 'pointer' }
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Stack direction="column" minWidth="180px" maxWidth="100%">
          <MenuList>
            <Typography
              variant="subtitle2"
              color={theme.palette.secondary[300]}
              p="8px 8px 8px 12px"
              borderBottom={`1px solid ${theme.palette.secondary[700]}`}
            >
              {columnName}
            </Typography>
            {renderFilterButton(
              EFilterOperatorWithValue.is,
              EFilterOperatorWithoutValue.notExists,
              EIcon.PlusCircle,
              t('common:tableCellFilters_is')
            )}
            {renderFilterButton(
              EFilterOperatorWithValue.isNot,
              EFilterOperatorWithoutValue.exists,
              EIcon.MinusCircle,
              t('common:tableCellFilters_is_not')
            )}
            <MenuItem
              onClick={() => {
                copyToClipboard(value, t);
                handleClose();
              }}
              className="MuiMenuItem-root--smaller"
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ padding: 0 }}>
                <Typography variant="subtitle2">{t('common:copyValue')}</Typography>
                <Icon name={EIcon.Copy} fontSize="small" />
              </Stack>
            </MenuItem>
          </MenuList>
        </Stack>
      </Menu>
      <Tooltip
        placement="top"
        title={hasTooltip && displayedValue}
        componentsProps={{
          tooltip: {
            sx: {
              background: theme.palette.secondary[600],
              width: '500px',
              maxHeight: '50vh',
              overflowY: 'auto',
              overflowX: 'hidden'
            }
          }
        }}
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Stack
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all'
          }}
          component="div"
        >
          {displayedValue}
        </Stack>
      </Tooltip>
    </Stack>
  );
}

export default DecisionPointsColumnItem;

import { Spinner } from '@arcanna/generic';
import { EditorView } from '@codemirror/view';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { tokyoNight } from '@uiw/codemirror-theme-tokyo-night';
import { EditorState, Extension, Transaction } from '@uiw/react-codemirror';
import { default as LibraryCodeMirror, keymap } from '@uiw/react-codemirror';
import { indentWithTab as indentWithTabKeymap } from '@codemirror/commands';
import { forwardRef, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showWarningNotification } from 'src/components/shared/utilities/notification';
import { getCodeMirrorTheme } from './CodeMirror.utils';
import _ from 'lodash';

export type TCodeMirrorReadOnlyRanges = Array<{ from: number; to: number }>;

type TCodeMirrorProps = {
  mode: 'light' | 'dark';
  isError?: boolean;
  isEditable?: boolean;
  getReadOnlyRanges?: (targetState: EditorState) => TCodeMirrorReadOnlyRanges;
  value: string;
  extensions: Extension[];
  isLoading?: boolean;
  indentWithTab?: boolean;
  lineWrapping?: boolean;
  height: string;
  onChange?: (value: string) => void;
  gutterBorderRadius?: string;
  contentBorderRadius?: string;
  fieldError?: FieldError | undefined;
  blockedLines?: {
    title: string;
    message: string;
  };
};

const CodeMirror = forwardRef<HTMLDivElement, TCodeMirrorProps>(
  (
    {
      mode,
      isError,
      isEditable,
      getReadOnlyRanges,
      value,
      extensions,
      isLoading,
      indentWithTab = true,
      lineWrapping = true,
      height = '500px',
      onChange,
      gutterBorderRadius,
      contentBorderRadius = '4px',
      fieldError,
      blockedLines
    },
    ref
  ) => {
    const { palette } = useTheme();
    const { t } = useTranslation(['common']);

    const debouncedWarningNotification = useMemo(
      () =>
        _.debounce((title, message) => {
          showWarningNotification(title, message);
        }, 500),
      []
    );

    // Used to make specific lines of code read-only
    const readOnlyTransactionFilter = useCallback(() => {
      return EditorState.transactionFilter.of((tr) => {
        if (getReadOnlyRanges) {
          const readOnlyRanges = getReadOnlyRanges(tr.startState);
          if (tr.docChanged && !tr.annotation(Transaction.remote)) {
            let block = false;
            tr.changes.iterChangedRanges((chFrom, chTo) => {
              readOnlyRanges.forEach(({ from, to }) => {
                if (chTo >= from && chFrom <= to) block = true;
              });
            });
            if (block) {
              debouncedWarningNotification(
                blockedLines?.title || t('common:blockedLines.title'),
                blockedLines?.message || t('common:blockedLines.subtitle')
              );
              return [];
            }
          }
        }
        return tr;
      });
    }, [blockedLines?.message, blockedLines?.title, debouncedWarningNotification, getReadOnlyRanges, t]);

    const verifiedExtensions = useMemo(() => {
      // Do not make theme as a prop, we want consistent theme for all the code mirrors using tokyoNight theme
      // and our custom css
      const tempExtensions = [...extensions, tokyoNight, keymap.of([indentWithTabKeymap])];
      if (lineWrapping) {
        tempExtensions.push(EditorView.lineWrapping);
      }
      if (getReadOnlyRanges) {
        tempExtensions.push(readOnlyTransactionFilter());
      }
      return tempExtensions;
    }, [extensions, getReadOnlyRanges, lineWrapping, readOnlyTransactionFilter]);

    if (isLoading) {
      return (
        <Box
          position="relative"
          width="100%"
          height={height}
          bgcolor={mode === 'dark' ? palette.background.default : palette.secondary[900]}
          border="1px solid"
          borderRadius={contentBorderRadius}
          borderColor={palette.secondary[700]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      );
    }

    return (
      <Stack
        ref={ref}
        gap="4px"
        // We copy some styles from the CodeMirror theme so that, when it renders slowly, it won't look that bad
        height={height}
        borderRadius={contentBorderRadius}
        bgcolor={mode === 'dark' ? palette.background.default : palette.secondary[900]}
      >
        <LibraryCodeMirror
          value={value}
          theme={getCodeMirrorTheme({
            mode,
            palette,
            hasError: isError || !!fieldError,
            guttersBorderRadius: gutterBorderRadius,
            contentBorderRadius: contentBorderRadius
          })}
          onChange={onChange}
          editable={isEditable}
          extensions={verifiedExtensions}
          height={height}
          indentWithTab={indentWithTab}
          basicSetup={{
            tabSize: 4,
            foldGutter: true,
            foldKeymap: true,
            lintKeymap: true,
            indentOnInput: true
          }}
        />
        {fieldError && (
          <Typography variant="subtitle2" color={palette.error.main}>
            {fieldError?.message}
          </Typography>
        )}
      </Stack>
    );
  }
);

export default CodeMirror;

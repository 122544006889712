import * as React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { JobFeatureSelectionUpdateRequest } from '../../components/shared/models/job/JobFeatureSelectionUpdateRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { config } from '../../config';
import { JobUpdateResponse } from '../../components/shared/models/job/JobUpdateResponse';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';

export function useSaveFeatureSelection() {
  const { t } = useTranslation(['common', 'job']);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const queryClient = useQueryClient();
  return useMutation((payload: JobFeatureSelectionUpdateRequest) => axios.post(config.api.jobFeatureSelectionUpdate, payload), {
    onSuccess: async (response, payload) => {
      const responseData = jsonConvert.deserializeObject(response.data.resource, JobUpdateResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (responseData.request.status === REQUEST_STATUS_NOK) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        showErrorNotification(t('common:error'), responseData.request.reason);
        return;
      }

      await queryClient.invalidateQueries([config.api.jobFeatureSelectionSummary, { job_id: payload.job_id }]);
      await queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${payload.job_id}`)]);
      await queryClient.invalidateQueries([config.api.jobFeedbackFilterFields + `${payload.job_id}`]);
      await queryClient.invalidateQueries([config.api.jobFeatureSelectionUnifiedSummary]);

      showSuccessNotification(t('common:actionCompleted'), t('job:updateSuccessfully'));
    },
    onError: () => {
      showErrorNotification(t('common:error'), t('common:updateError'));
    }
  });
}

import { Stack, StackProps, alpha, styled } from '@mui/material';

const StyledGeneralCellWithFilters = styled(Stack)<StackProps>(({ theme }) => {
  return {
    '.filters': {
      display: 'none'
    },

    '&:hover': {
      '.filters': {
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        gap: 0.25,
        alignItems: 'center',
        position: 'absolute',
        right: 0,
        top: 0,
        background: theme.palette.secondary[800],
        paddingLeft: '2px',
        boxShadow: `-4px 0px 40px 0px ${alpha(theme.palette.secondary[800], 0.75)}`
      }
    }
  };
});

export default StyledGeneralCellWithFilters;
